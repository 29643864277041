import * as anchor from '@project-serum/anchor';
import { WalletContextState } from "@solana/wallet-adapter-react";
import MintBooster from '../../components/mints/mint-booster/mint-booster';

const Artworks = ({ wallet }: { wallet: WalletContextState }) => {
    const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
    const connection = new anchor.web3.Connection(
        rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
    );

    const artworks = [
        { image: "/astral_trip.png", booster_cm_id: "As1dH2tKJbWwqhkHBW9pziDBoRVduAuX13K3S8DRBViK", token: "SOL" },
    ];

    return (
        <div className="relative w-full min-h-screen bg-black overflow-x-hidden">
            <div className="w-full flex justify-center items-center px-8 sm:px-16 sm:py-6">
                <div className="w-full h-auto p-1 text-center">
                    <h1 className="uppercase font-extrabold text-transparent font-archivo text-4xl bg-clip-text bg-gradient-to-b from-violet-100 to-violet-500 lg:whitespace-nowrap mt-5 mb-10">Artwork drops</h1>
                </div>
            </div>
            <div className="w-full h-full text-white p-8 flex flex-row flex-wrap justify-around items-center">
                {
                    artworks.map(artwork => (
                        <div key={artwork.booster_cm_id} className={`w-full sm:w-80 flex flex-col p-4 justify-center items-center border-2 my-4 ${artwork.token === "SOL" ? "border-violet-500" : "border-orange-300"}`}>
                            <MintBooster boosterInfo={artwork} connection={connection} wallet={wallet} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Artworks;