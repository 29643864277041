import {
    CANDY_MACHINE, CANDY_MACHINE_PROGRAM_V2_ID,
} from "./constants";

import { PublicKey } from "@solana/web3.js";
import * as anchor from "@project-serum/anchor";

export const deriveCandyMachineV2ProgramAddress = async (
    candyMachineId: anchor.web3.PublicKey,
): Promise<[PublicKey, number]> => {
    return await PublicKey.findProgramAddress(
        [Buffer.from(CANDY_MACHINE), candyMachineId.toBuffer()],
        CANDY_MACHINE_PROGRAM_V2_ID,
    );
};