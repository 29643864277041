import { Comic } from "../../../helpers/interfaces";
import { Connection } from '@solana/web3.js';
import ConnectButton from '../../buttons/connect-button/connect-button';
import { getCandyMachineState } from "../../buttons/mint-button/candy-machine"
import { ImageInfo } from "../../../helpers/interfaces";
import { formatNumber } from "../../buttons/mint-button/utils";
import MintButton from '../../buttons/mint-button/mint-button';
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';
import { deriveCandyMachineV2ProgramAddress } from "../../../helpers/metaplex";

const MintPromo = ({ comicCollection, connection, comic, setComicPreview, wallet, getComicCollection }: { comicCollection: any[] | undefined, connection: Connection, comic: Comic | undefined, setComicPreview: React.Dispatch<React.SetStateAction<Comic | undefined>>, wallet: WalletContextState, getComicCollection?: any }) => {
    const [cover, setCover] = useState<string>("");
    const [previewImages, setPreviewImages] = useState<ImageInfo[]>([]);
    const [candyMachine, setCandyMachine] = useState<any>();
    const [candyMachineAddress, setCandyMachineAddress] = useState<string>();

    useEffect(() => {
        if (comic) {
            let coverInfo: ImageInfo = comic.images.filter(image => image.image_type === "cover")[0];
            let cover: string = coverInfo.image_url;
            setCover(cover);

            let previewImages: ImageInfo[] = comic.images.filter(image => image.image_type === "preview");
            setPreviewImages(previewImages);
        }
    }, [comic])

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet])

    useEffect(() => {
        const fetchCandyMachineInfo = async () => {
            if (anchorWallet && comic) {
                const candyMachineId = new anchor.web3.PublicKey(
                    comic.comic_cm_id,
                );
                let cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
                setCandyMachine(cndy);

                let [candyMachineAddr] = await deriveCandyMachineV2ProgramAddress(candyMachineId);
                setCandyMachineAddress(candyMachineAddr.toString());
            }
        }

        fetchCandyMachineInfo();
    }, [anchorWallet, comic, connection])

    return (
        <div className="w-full h-auto lg:h-full flex justify-center items-center bg-black px-10 py-5">
            <div className="w-full min-h-screen-1/2 bg-gradient-to-b from-violet-500 via-pink-500 to-orange-300 p-1">
                <div className="relative w-full min-h-screen-1/2 h-full p-2 sm:p-10 px-4 sm:px-20 bg-black text-white flex flex-row flex-wrap justify-around items-center overflow-hidden ">
                    {
                        comic ?
                            <>
                                <div className="hidden lg:flex w-1/3 h-full justify-center items-center p-5">
                                    <img className="w-auto h-96 shadow-purple" src={cover} alt="random" />
                                </div>
                                <div className="w-full sm:w-2/3 h-full flex justify-center items-center">
                                    <div className="w-full sm:w-128 h-full text-center lg:text-left">
                                        <h1 className="uppercase font-extrabold text-transparent font-archivo text-4xl bg-clip-text bg-gradient-to-b from-sky-100 to-sky-500 lg:whitespace-nowrap mt-5 mb-10">Drop of the month</h1>

                                        <img className="lg:hidden w-full sm:w-128 h-auto shadow-purple" src={cover} alt="random" />

                                        <div className="w-full my-14">
                                            <div className="mb-4">
                                                <h2 className="font-bold text-xl uppercase mb-1">{comic.series.series_name}</h2>
                                                <h3>{comic.comic_name}</h3>
                                            </div>
                                            <div className={`flex flex-row whitespace-nowrap mb-4 justify-between ${!candyMachine?.state && "w-full lg:w-1/2"}`}>
                                                <p>Episode {comic.comic_episode_number}</p>
                                                <p>|</p>
                                                <p>{comic.comic_page_count} pages</p>
                                                {
                                                    candyMachine && candyMachine?.state &&
                                                    <>
                                                        <p>|</p>
                                                        <p>
                                                            {`Edition: ${candyMachine.state.itemsAvailable}`}
                                                        </p>
                                                        <p>|</p>
                                                        <p>
                                                            {`${formatNumber.asNumber(candyMachine.state.price)} SOL`}
                                                        </p>
                                                    </>
                                                }
                                            </div>
                                            <p>{comic.comic_description}</p>
                                        </div>
                                        <div className="w-full flex flex-row flex-wrap justify-center lg:justify-start mb-20 gap-10">
                                            {
                                                previewImages?.length > 0 ?
                                                    <button className="bg-violet-800 hover:bg-purple-800 text-white w-24 py-1 rounded-full uppercase text-sm"
                                                        onClick={() => setComicPreview(comic)}
                                                    >
                                                        Preview
                                                    </button>
                                                    :
                                                    <button className="bg-gray-800 text-white w-24 py-1 rounded-full uppercase text-sm cursor-default">
                                                        Preview
                                                    </button>
                                            }
                                            {
                                                !wallet || !wallet.connected ?
                                                    <ConnectButton color={"#7d58db"} />
                                                    :
                                                    <MintButton candyMachineIdString={comic.comic_cm_id} color={"violet-500"} isPromo={true} wallet={wallet} getComicCollection={getComicCollection} />
                                            }
                                            {
                                                !wallet || !wallet.connected ?
                                                    <button className="bg-gray-500 text-gray-400 w-24 py-1 rounded-full uppercase text-sm">
                                                        Read
                                                    </button>
                                                    :
                                                    !comicCollection ?
                                                        <button className="bg-violet-400 hover:bg-violet-500 text-white w-24 py-1 rounded-full uppercase flex justify-center items-center">
                                                            <div className="w-4 h-4 border-l-2 border-white rounded-full animate-spin" />
                                                        </button>
                                                        :
                                                        comicCollection.some(owned_comic => owned_comic.candyMachineAddress === candyMachineAddress) ?
                                                            <Link to={{ pathname: `/comic-reader/${candyMachineAddress}` }} >
                                                                <button className="bg-violet-400 hover:bg-violet-500 text-white w-24 py-1 rounded-full uppercase text-sm">
                                                                    Read
                                                                </button>
                                                            </Link>
                                                            :
                                                            <button className="bg-gray-500 text-gray-400 w-24 py-1 rounded-full uppercase text-sm">
                                                                Read
                                                            </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="absolute bottom-10 -right-2 w-96 pl-5 flex justify-end">
                                    <div className="z-0 transform -skew-x-25 w-full h-8 flex justify-center items-center bg-gradient-to-r from-violet-500 via-pink-500 to-orange-300 text-black">
                                    </div>
                                </div>
                                <p className="absolute text-black bottom-12 right-16 font-extrabold uppercase text-sm">Includes special airdrop</p> */}
                            </>
                            :
                            <div className="w-24 h-24 border-l-2 border-purple-600 rounded-full animate-spin" />
                    }
                </div>
            </div>
        </div >
    )
}

export default MintPromo;