import { Comic } from "../../../helpers/interfaces";
import { Connection } from '@solana/web3.js';
import ConnectButton from "../../buttons/connect-button/connect-button";
import CharacterSwiper from "../../swipers/character-swiper/character-swiper";
import { deriveCandyMachineV2ProgramAddress } from "../../../helpers/metaplex";
import { getCandyMachineState } from "../../buttons/mint-button/candy-machine";
import { formatNumber } from "../../buttons/mint-button/utils";
import MintButton from "../../buttons/mint-button/mint-button";
import { Link } from "react-router-dom";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { useEffect, useMemo, useState } from "react";
import * as anchor from '@project-serum/anchor';

const MintComic = ({ comicCollection, connection, comic, setComicPreview, wallet, getComicCollection }: { comicCollection: any[] | undefined, connection: Connection, comic: Comic, setComicPreview: React.Dispatch<React.SetStateAction<Comic | undefined>>, wallet: WalletContextState, getComicCollection: any }) => {
    const [candyMachine, setCandyMachine] = useState<any>();
    const [candyMachineAddress, setCandyMachineAddress] = useState<string>();

    let previewImages = comic && comic?.images.filter(comic => comic.image_type === "preview");

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    useEffect(() => {
        const fetchCandyMachineInfo = async () => {
            if (anchorWallet) {
                const candyMachineId = new anchor.web3.PublicKey(
                    comic.comic_cm_id,
                );
                let cndy = await getCandyMachineState(anchorWallet, candyMachineId, connection);
                setCandyMachine(cndy);

                let [candyMachineAddr] = await deriveCandyMachineV2ProgramAddress(candyMachineId);
                setCandyMachineAddress(candyMachineAddr.toString());
            }
        }

        fetchCandyMachineInfo();
    }, [anchorWallet, comic, connection])

    return (
        <div className="w-76 h-full mb-6 flex flex-col justify-center items-center border-2 border-orange-300 p-4 rounded">
            <div className="mb-6 flex flex-col items-center">
                <img className="w-full h-96 object-cover mb-4 border-2 border-gray-500 p-2 rounded" src={comic.images[0].image_url} alt={`${comic.comic_name} cover`} />
                <h2 className="text-white font-bold text-lg uppercase">{comic.series.series_name}</h2>
                <h3>{comic.comic_name}</h3>
                <p>{`Episode ${comic.comic_episode_number}`}</p>
                <div className="flex flex-row whitespace-nowrap mb-4 justify-between">
                    <p>{`${comic.comic_page_count} pages`}</p>
                    {
                        candyMachine && candyMachine?.state &&
                        <>
                            <p className="mx-2">|</p>
                            <p>
                                {`Edition: ${candyMachine.state.itemsAvailable}`}
                            </p>
                            <p className="mx-2">|</p>
                            <p>
                                {`${formatNumber.asNumber(candyMachine.state.price)} SOL`}
                            </p>
                        </>
                    }
                </div>
                <div className="w-full flex flex-row justify-around mb-4">
                    {
                        previewImages?.length > 0 ?
                            <button className="
                            border-2 border-orange-400 hover:bg-orange-400 
                            text-white w-24 py-1 rounded-full uppercase text-sm"
                                onClick={() => setComicPreview(comic)}
                            >
                                Preview
                            </button>
                            :
                            <button className="
                            border-2 border-gray-600 text-gray-600 
                            w-24 py-1 rounded-full uppercase text-sm cursor-default"
                            >
                                Preview
                            </button>
                    }
                    {
                        !wallet || !wallet.connected ?
                            <ConnectButton />
                            :
                            comicCollection && comicCollection.some(comic => comic.candyMachineAddress === candyMachineAddress) ?
                                <Link to={{ pathname: `/comic-reader/${candyMachineAddress}` }} >
                                    <button className="border-2 border-orange-400 hover:bg-orange-400 text-white w-24 py-1 rounded-full uppercase text-sm">
                                        Read
                                    </button>
                                </Link>
                                :
                                <MintButton candyMachineIdString={comic.comic_cm_id} isPromo={false} wallet={wallet} getComicCollection={getComicCollection} />
                    }
                </div>
            </div>
            {
                comic.characters?.length > 0 &&
                <CharacterSwiper comic={comic} connection={connection} characterList={comic.characters} wallet={wallet} />
            }
        </div>
    )
}

export default MintComic