import { AlertState, getAtaForMint, toDate } from './utils';
import {
    awaitTransactionSignatureConfirmation,
    CandyMachineAccount,
    createAccountsForMint,
    getCandyMachineState,
    getCollectionPDA,
    mintOneToken,
    SetupState,
} from './candy-machine';
import {
    Commitment,
    Connection,
    Transaction,
} from '@solana/web3.js';
import { DEFAULT_TIMEOUT } from './connection';
import { getHoverColor } from '../../../helpers/colors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as anchor from '@project-serum/anchor';

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
    rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const MintButton = ({ candyMachineIdString, color, isPromo, wallet, getComicCollection }: { candyMachineIdString: string, color?: string | undefined, isPromo: boolean, wallet: any, getComicCollection?: any }) => {
    // eslint-disable-next-line
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: '',
        severity: undefined,
    });
    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
    // eslint-disable-next-line
    const [isActive, setIsActive] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [itemsRemaining, setItemsRemaining] = useState<number>();
    const [needTxnSplit, setNeedTxnSplit] = useState(true);
    const [setupTxn, setSetupTxn] = useState<SetupState>();
    // eslint-disable-next-line
    const [discountPrice, setDiscountPrice] = useState<anchor.BN>();
    // eslint-disable-next-line
    const [isWhitelistUser, setIsWhitelistUser] = useState(false);
    // eslint-disable-next-line
    const [isValidBalance, setIsValidBalance] = useState(false);
    // eslint-disable-next-line
    const [endDate, setEndDate] = useState<Date>();
    // eslint-disable-next-line
    const [isPresale, setIsPresale] = useState(false);

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    const refreshCandyMachineState = useCallback(
        async (commitment: Commitment = 'confirmed') => {
            try {
                if (!anchorWallet) {
                    return;
                }

                const connection = new Connection(rpcHost, commitment);

                if (candyMachineIdString) {
                    const candyMachineId = new anchor.web3.PublicKey(
                        candyMachineIdString,
                    );
                    try {
                        const cndy = await getCandyMachineState(
                            anchorWallet,
                            candyMachineId,
                            connection,
                        );
                        let active =
                            cndy?.state.goLiveDate?.toNumber() < new Date().getTime() / 1000;
                        let presale = false;

                        // duplication of state to make sure we have the right values!
                        let isWLUser = false;
                        let userPrice = cndy.state.price;

                        // whitelist mint?
                        if (cndy?.state.whitelistMintSettings) {
                            // is it a presale mint?
                            if (
                                cndy.state.whitelistMintSettings.presale &&
                                (!cndy.state.goLiveDate ||
                                    cndy.state.goLiveDate.toNumber() > new Date().getTime() / 1000)
                            ) {
                                presale = true;
                            }
                            // is there a discount?
                            if (cndy.state.whitelistMintSettings.discountPrice) {
                                setDiscountPrice(cndy.state.whitelistMintSettings.discountPrice);
                                userPrice = cndy.state.whitelistMintSettings.discountPrice;
                            } else {
                                setDiscountPrice(undefined);
                                // when presale=false and discountPrice=null, mint is restricted
                                // to whitelist users only
                                if (!cndy.state.whitelistMintSettings.presale) {
                                    cndy.state.isWhitelistOnly = true;
                                }
                            }
                            // retrieves the whitelist token
                            const mint = new anchor.web3.PublicKey(
                                cndy.state.whitelistMintSettings.mint,
                            );
                            const token = (
                                await getAtaForMint(mint, anchorWallet.publicKey)
                            )[0];

                            try {
                                const balance = await connection.getTokenAccountBalance(token);
                                isWLUser = parseInt(balance.value.amount) > 0;
                                // only whitelist the user if the balance > 0
                                setIsWhitelistUser(isWLUser);

                                if (cndy.state.isWhitelistOnly) {
                                    active = isWLUser && (presale || active);
                                }
                            } catch (e) {
                                setIsWhitelistUser(false);
                                // no whitelist user, no mint
                                if (cndy.state.isWhitelistOnly) {
                                    active = false;
                                }
                                console.log(
                                    'There was a problem fetching whitelist token balance',
                                );
                                console.log(e);
                            }
                        }
                        userPrice = isWLUser ? userPrice : cndy.state.price;

                        if (cndy?.state.tokenMint) {
                            // retrieves the SPL token
                            const mint = new anchor.web3.PublicKey(cndy.state.tokenMint);
                            const token = (
                                await getAtaForMint(mint, anchorWallet.publicKey)
                            )[0];
                            try {
                                const balance = await connection.getTokenAccountBalance(token);

                                const valid = new anchor.BN(balance.value.amount).gte(userPrice);

                                // only allow user to mint if token balance >  the user if the balance > 0
                                setIsValidBalance(valid);
                                active = active && valid;
                            } catch (e) {
                                setIsValidBalance(false);
                                active = false;
                                // no whitelist user, no mint
                                console.log('There was a problem fetching SPL token balance');
                                console.log(e);
                            }
                        } else {
                            const balance = new anchor.BN(
                                await connection.getBalance(anchorWallet.publicKey),
                            );
                            const valid = balance.gte(userPrice);
                            setIsValidBalance(valid);
                            active = active && valid;
                        }

                        // datetime to stop the mint?
                        if (cndy?.state.endSettings?.endSettingType.date) {
                            setEndDate(toDate(cndy.state.endSettings.number));
                            if (
                                cndy.state.endSettings.number.toNumber() <
                                new Date().getTime() / 1000
                            ) {
                                active = false;
                            }
                        }
                        // amount to stop the mint?
                        if (cndy?.state.endSettings?.endSettingType.amount) {
                            let limit = Math.min(
                                cndy.state.endSettings.number.toNumber(),
                                cndy.state.itemsAvailable,
                            );
                            if (cndy.state.itemsRedeemed < limit) {
                                setItemsRemaining(limit - cndy.state.itemsRedeemed);
                            } else {
                                setItemsRemaining(0);
                                cndy.state.isSoldOut = true;
                            }
                        } else {
                            setItemsRemaining(cndy.state.itemsRemaining);
                        }

                        if (cndy.state.isSoldOut) {
                            active = false;
                        }

                        const [collectionPDA] = await getCollectionPDA(candyMachineId);
                        const collectionPDAAccount = await connection.getAccountInfo(
                            collectionPDA,
                        );

                        setIsActive((cndy.state.isActive = active));
                        setIsPresale((cndy.state.isPresale = presale));
                        setCandyMachine(cndy);

                        const txnEstimate =
                            892 +
                            (!!collectionPDAAccount && cndy.state.retainAuthority ? 182 : 0) +
                            (cndy.state.tokenMint ? 66 : 0) +
                            (cndy.state.whitelistMintSettings ? 34 : 0) +
                            (cndy.state.whitelistMintSettings?.mode?.burnEveryTime ? 34 : 0) +
                            (cndy.state.gatekeeper ? 33 : 0) +
                            (cndy.state.gatekeeper?.expireOnUse ? 66 : 0);

                        setNeedTxnSplit(txnEstimate > 1230);
                    } catch (e) {
                        if (e instanceof Error) {
                            if (
                                e.message === `Account does not exist ${candyMachineId}`
                            ) {
                                setIsClosed(true);
                                setAlertState({
                                    open: true,
                                    message: `Couldn't fetch candy machine state from candy machine with address: ${candyMachineId}, using rpc: ${rpcHost}! You probably typed the REACT_APP_CANDY_MACHINE_ID value in wrong in your .env file, or you are using the wrong RPC!`,
                                    severity: 'error',
                                    hideDuration: null,
                                });
                            } else if (
                                e.message.startsWith('failed to get info about account')
                            ) {
                                setAlertState({
                                    open: true,
                                    message: `Couldn't fetch candy machine state with rpc: ${rpcHost}! This probably means you have an issue with the REACT_APP_SOLANA_RPC_HOST value in your .env file, or you are not using a custom RPC!`,
                                    severity: 'error',
                                    hideDuration: null,
                                });
                            }
                        } else {
                            setAlertState({
                                open: true,
                                message: `${e}`,
                                severity: 'error',
                                hideDuration: null,
                            });
                        }
                        // console.log(e);
                    }
                } else {
                    setAlertState({
                        open: true,
                        message: `Your REACT_APP_CANDY_MACHINE_ID value in the .env file doesn't look right! Make sure you enter it in as plain base-58 address!`,
                        severity: 'error',
                        hideDuration: null,
                    });
                }
            } catch (err) {
                //console.log(err);
            }
        },
        [anchorWallet, candyMachineIdString],
    );

    const onMint = async (
        beforeTransactions: Transaction[] = [],
        afterTransactions: Transaction[] = [],
    ) => {
        try {
            document.getElementById('#identity')?.click();
            console.log(candyMachine?.program);
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                console.log("STEP 2")
                let setupMint: SetupState | undefined;
                if (needTxnSplit && setupTxn === undefined) {
                    setAlertState({
                        open: true,
                        message: 'Please sign account setup transaction',
                        severity: 'info',
                    });
                    setupMint = await createAccountsForMint(
                        candyMachine,
                        wallet.publicKey,
                    );
                    let status: any = { err: true };
                    if (setupMint.transaction) {
                        status = await awaitTransactionSignatureConfirmation(
                            setupMint.transaction,
                            DEFAULT_TIMEOUT,
                            connection,
                            true,
                        );
                    }
                    if (status && !status.err) {
                        if (getComicCollection) {
                            getComicCollection();
                        }
                        setSetupTxn(setupMint);
                        setAlertState({
                            open: true,
                            message:
                                'Setup transaction succeeded! Please sign minting transaction',
                            severity: 'info',
                        });
                    } else {
                        setAlertState({
                            open: true,
                            message: 'Mint failed! Please try again!',
                            severity: 'error',
                        });
                        // setIsUserMinting(false);
                        return;
                    }
                } else {
                    setAlertState({
                        open: true,
                        message: 'Please sign minting transaction',
                        severity: 'info',
                    });
                }

                let mintResult = await mintOneToken(
                    candyMachine,
                    wallet.publicKey,
                    beforeTransactions,
                    afterTransactions,
                    setupMint ?? setupTxn,
                );

                let status: any = { err: true };
                let metadataStatus = null;
                if (mintResult) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintResult.mintTxId,
                        DEFAULT_TIMEOUT,
                        connection,
                        true,
                    );

                    metadataStatus =
                        await candyMachine.program.provider.connection.getAccountInfo(
                            mintResult.metadataKey,
                            'processed',
                        );
                    console.log('Metadata status: ', !!metadataStatus);
                }

                if (status && !status.err && metadataStatus) {
                    // manual update since the refresh might not detect
                    // the change immediately
                    let remaining = itemsRemaining! - 1;
                    setItemsRemaining(remaining);
                    setIsActive((candyMachine.state.isActive = remaining > 0));
                    candyMachine.state.isSoldOut = remaining === 0;
                    setSetupTxn(undefined);
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                        hideDuration: 7000,
                    });
                    refreshCandyMachineState('processed');
                } else if (status && !status.err) {
                    setAlertState({
                        open: true,
                        message:
                            'Mint likely failed! Anti-bot SOL 0.01 fee potentially charged! Check the explorer to confirm the mint failed and if so, make sure you are eligible to mint before trying again.',
                        severity: 'error',
                        hideDuration: 8000,
                    });
                    refreshCandyMachineState();
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                    refreshCandyMachineState();
                }
            }
        } catch (error: any) {
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction timeout! Please try again.';
                } else if (error.message.indexOf('0x137')) {
                    console.log(error);
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    console.log(error);
                    message = `SOLD OUT!`;
                    window.location.reload();
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            });
            // updates the candy machine state to reflect the latest
            // information on chain
            refreshCandyMachineState();
        } finally {
            // setIsUserMinting(false);
        }
    };

    useEffect(() => {
        refreshCandyMachineState();
    }, [
        anchorWallet,
        candyMachineIdString,
        refreshCandyMachineState,
    ]);

    const randomNumber = (min: number, max: number) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    useEffect(() => {
        (function loop() {
            setTimeout(() => {
                refreshCandyMachineState();
                loop();
            }, randomNumber(40, 60) * 1000);
        })();
    }, [refreshCandyMachineState]);

    const defaultColor = "orange-400";
    const defaultHoverColor = "orange-500";
    const hoverColor = color && getHoverColor(color);

    let buttonStyle = `border-2 border-${color || defaultColor} hover:bg-${hoverColor || defaultHoverColor} text-white w-24 py-1 rounded-full uppercase text-sm`;
    if (isPromo) {
        buttonStyle = `bg-${color || defaultColor} hover:bg-${hoverColor || defaultHoverColor} text-white w-24 py-1 rounded-full uppercase text-sm`
    }

    if (isClosed || (endDate && Date.now() > endDate.getTime())) {
        return (
            <button className={`border-2 border-gray-600 text-gray-600 w-24 py-1 rounded-full uppercase text-sm`}>
                Closed
            </button>
        )
    } else if (candyMachine && candyMachine?.state.itemsRemaining <= 0) {
        return (
            <button className={`border-2 border-gray-600 text-gray-600 w-24 py-1 rounded-full uppercase text-sm`}>
                Sold out
            </button>
        )
    } else {
        return (
            <button className={buttonStyle}
                onClick={() => onMint()}
            >
                Mint
            </button>
        )
    }
}

export default MintButton;