import { Comic } from "../../helpers/interfaces";
import ReactCarousel from "react-multi-carousel";

const responsive = {
    largeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 2,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 800 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 800, min: 0 },
        items: 2,
    },
};

const PreviewModal = ({ comicPreview, setComicPreview }: { comicPreview: Comic | undefined, setComicPreview: React.Dispatch<React.SetStateAction<Comic | undefined>> }) => {
    let previewImages = comicPreview && comicPreview?.images.filter(comic => comic.image_type === "preview");

    return (
        <div className="
            fixed top-0 w-screen h-screen z-20 
            flex justify-center items-center"
            onClick={() => setComicPreview(undefined)}
        >
            <div className="w-9/12 h-10/12 bg-black rounded-lg p-10 z-20 flex flex-col justify-center items-center border-2 border-blue-800"
                onClick={e => e.stopPropagation()}
            >
                {
                    comicPreview &&
                    <div className="w-full xl:w-1/3 text-white text-lg sm:text-xl flex flex-row justify-around whitespace-nowrap">
                        <h1>{comicPreview.comic_name}</h1>
                        <p>|</p>
                        <p>Episode {comicPreview.comic_episode_number}</p>
                    </div>
                }
                {
                    previewImages?.length === 1 ?
                        <img className="w-auto h-80 mb-4" src={previewImages[0].image_url} alt="preview" />
                        :
                        <ReactCarousel
                            className="w-full h-full z-20"
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={true}
                            infinite={false}
                            autoPlay={false}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            focusOnSelect={false}
                            customTransition="transform 300ms ease-in-out"
                            transitionDuration={300}
                        >
                            {
                                previewImages && previewImages?.length > 0 ?
                                    previewImages.map((image, index) => (
                                        <img key={index} className="w-auto h-80 mb-4 object-cover" src={image.image_url} alt={`preview ${index}`} />
                                    ))
                                    :
                                    <></>
                            }
                        </ReactCarousel>
                }
            </div>
        </div>
    )
}

export default PreviewModal;