
import {
    WalletModalProvider,
    WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import "@solana/wallet-adapter-react-ui/styles.css";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <div className="w-full h-auto bg-black text-white flex flex-wrap sm:flex-nowrap justify-center sm:justify-between items-center p-6 px-16 gap-12">
            <Link to={"/"} className="sm:flex-grow flex justify-center sm:justify-start">
                <img className="w-52 h-auto" src="/RD_logo_1600.png" alt="RainyDayz" />
            </Link>
            <Link to={"/"} >
                <h1 className="text-lg text-violet-400 uppercase">Comics</h1>
            </Link>
            <Link to={"/boosters"} >
                <h1 className="text-lg whitespace-nowrap text-violet-400 uppercase">Booster packs</h1>
            </Link>
            <Link to={"/artworks"} >
                <h1 className="text-lg whitespace-nowrap text-violet-400 uppercase">Artworks</h1>
            </Link>
            <div className="sm:ml-6 sm:block whitespace-nowrap" style={{ "color": "white" }}>
                <WalletModalProvider >
                    <WalletMultiButton style={{ "color": "white", "backgroundColor": "#7d58db", "fontSize": "14px" }} />
                </WalletModalProvider>
            </div>
        </div>
    )
}

export default Navbar;