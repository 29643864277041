import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import Placeholder from "./placeholder";

const Pages = ({ comicInfo, scrollPosition }: { comicInfo: any, scrollPosition: any }) => {
  return (
    <>
      {
        comicInfo.content.map((image: string, index: number) => (
          <div key={image} className="w-full sm:w-7/12 md:6/12 lg:6/12 h-auto object-contain">
            <LazyLoadImage
              className="w-full sm:min-h-screen my-3"
              alt={`page-${index}`}
              src={image}
              scrollPosition={scrollPosition}
              placeholder={<Placeholder />}
            />
          </div>
        ))
      }
    </>
  );

}

export default trackWindowScroll(Pages);
