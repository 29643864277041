import { useEffect, useState } from "react";
import { Comic } from "../../helpers/interfaces";
import HeroSwiper from "../../components/swipers/hero-swiper/hero-swiper";
import MintPromo from "../../components/mints/mint-promo/mint-promo";
import MintComic from "../../components/mints/mint-comic/mint-comic";
import PreviewModal from "../../components/modals/preview-modal";
import { WalletContextState } from "@solana/wallet-adapter-react";
import * as anchor from '@project-serum/anchor';

const Home = ({ comicCollection, wallet, getComicCollection }: { comicCollection: any[] | undefined, wallet: WalletContextState, getComicCollection: any }) => {
    // eslint-disable-next-line
    const [comicList, setComicList] = useState<Comic[]>([]);
    const [comicPreview, setComicPreview] = useState<Comic | undefined>();

    let areComicsFetched = false;

    const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
    const connection = new anchor.web3.Connection(
        rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
    );

    const fetchComics = () => {
        fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/comics/inventory`)
            .then(res => (res.json()))
            .then(res => {
                if (res && res.data) {
                    setComicList(res.data);
                    //setComicPreview(res.data[0]);
                    console.log(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (!areComicsFetched) {
            // console.log("FETCH")
            fetchComics();
            // eslint-disable-next-line
            areComicsFetched = true;
        }
    }, [])

    return (
        <div className="relative w-full min-h-screen bg-black overflow-x-hidden">
            <div className="w-full flex flex-row flex-wrap">
                {
                    comicList?.length > 0 ?
                        <HeroSwiper comicCollection={comicCollection} connection={connection} wallet={wallet} comicList={comicList} setComicPreview={setComicPreview} getComicCollection={getComicCollection}/>
                        :
                        <MintPromo comicCollection={comicCollection} connection={connection} comic={undefined} wallet={wallet} setComicPreview={setComicPreview} getComicCollection={getComicCollection}/>
                }
                <div className="w-full h-full text-white p-6 flex flex-col">
                    <div className="w-full flex flex-wrap justify-center items-center my-4">
                        <div className="w-screen h-full flex flex-row flex-wrap justify-around">
                            {
                                comicList?.length > 0 ?
                                    comicList.map((comic, index) => {
                                        return (
                                            <MintComic comicCollection={comicCollection} connection={connection} key={index} comic={comic} wallet={wallet} setComicPreview={setComicPreview} getComicCollection={getComicCollection}/>
                                        )
                                    })
                                    :
                                    <div className="w-screen min-h-screen-1/2 flex flex-row flex-wrap justify-between items-start px-10">
                                        <div className="w-76 h-80 border-2 border-orange-300 my-4" />
                                        <div className="w-76 h-80 border-2 border-orange-300 my-4" />
                                        <div className="w-76 h-80 border-2 border-orange-300 my-4" />
                                        <div className="w-76 h-80 border-2 border-orange-300 my-4" />
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                comicPreview &&
                <PreviewModal comicPreview={comicPreview} setComicPreview={setComicPreview} />
            }
        </div>
    )
}

export default Home;